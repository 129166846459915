@import '~uikit/dist/css/uikit.min.css';
@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');

a {
  text-decoration: none;
}

h1 {
  font-family: Staatliches;
}

.card-category {
  font-family: Staatliches;
}

.card-title {
  letter-spacing: 0.4px;
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 1.13636;
}

#banner {
  margin: 20px;
  height: 800px;
}

#editor {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.75;
}

.uk-navbar-container {
  background: #fff !important;
  font-family: Staatliches;
}

img:hover {
  opacity: 1;
  transition: opacity 0.25s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.cat-grid{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -1rem;
  &>div{
    flex-basis: 100%;
    @media(min-width: 480px) {
      flex-basis: 50%;
    }
    @media(min-width: 900px){
      flex-basis: 25%;
    }
    &:nth-child(6n + 1),
    &:nth-child(6n) {
      @media(min-width: 480px) {
        flex-basis: 50%;
      }
    }
    &:last-child{
      flex-basis: auto;
      // @media(min-width: 900px) {
      //   flex-basis: 50%;
      // }
      flex: 1;
    }
    &>div{
      padding: 0 1rem;
    }
  }
}

.cat-card{
  border-radius: 15px;
  margin-bottom: 2rem;
}